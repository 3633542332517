import React from 'react';
import PropTypes from 'prop-types';
import { Button, TableHead, TableHeader, TableRow, TableSelectRow, TableCell } from '@carbon/react';
import { Edit, TrashCan, Replicate } from '@carbon/react/icons';
import './OpsSelectionTable.scss';

export const HeadersRow = ({ columns }) => {
    const alignedHeaders = ['', ...columns];
    return (
        <TableHead>
            <TableRow>
                {alignedHeaders.map(({ header, key }) => (
                    <TableHeader id={header} key={`${header}_${key}`}>
                        {header}
                    </TableHeader>
                ))}
            </TableRow>
        </TableHead>
    );
};
HeadersRow.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export const RadioCell = ({ model, modelId, col, idx, selectedId, onSelect, disabled = false }) => {
    return (
        <TableSelectRow
            id={`${model['MODEL_NAME']}_${col}_${idx}`}
            ariaLabel="Select model for calculation"
            checked={modelId === selectedId}
            onSelect={() => onSelect(modelId)}
            name={model['MODEL_NAME'] ?? 'select'}
            radio
            disabled={disabled}
        />
    );
};
RadioCell.propTypes = {
    model: PropTypes.object.isRequired,
    modelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    col: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    idx: PropTypes.number.isRequired,
    selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export const ButtonsCell = ({
    childModel,
    model,
    modelId,
    onEdit,
    onDelete,
    showCloneButton = false,
    disabledDelete = false,
    disabledEdit = false,
}) => {
    if (showCloneButton) {
        return (
            <TableCell className="action-buttons">
                <Button
                    renderIcon={Replicate}
                    kind="ghost"
                    size="sm"
                    iconDescription="Copy Model"
                    onClick={() =>
                        onEdit(model, {
                            childModel,
                            modelId,
                            modelAction: 'copy',
                        })
                    }
                    aria-label="clone model"
                    tooltipPosition="left"
                    hasIconOnly
                    disabled={disabledEdit}
                />
            </TableCell>
        );
    }

    return (
        <TableCell className="action-buttons" style={{ height: 'inherit' }}>
            <Button
                renderIcon={Edit}
                kind="ghost"
                size="sm"
                iconDescription="Edit Model"
                onClick={() =>
                    onEdit(model, {
                        childModel,
                        modelId,
                        modelAction: 'edit',
                    })
                }
                aria-label="edit"
                tooltipPosition="left"
                hasIconOnly
                disabled={disabledEdit}
            />
            {typeof onDelete === 'function' ? (
                <Button
                    renderIcon={TrashCan}
                    className="ms-2"
                    kind="ghost"
                    size="sm"
                    iconDescription="Delete Model"
                    onClick={() => onDelete(modelId, 0)}
                    disabled={disabledDelete}
                    tooltipPosition="left"
                    hasIconOnly
                    aria-label="delete"
                />
            ) : null}
        </TableCell>
    );
};
ButtonsCell.propTypes = {
    childModel: PropTypes.array.isRequired,
    model: PropTypes.object.isRequired,
    modelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    showCloneButton: PropTypes.bool,
    disabledDelete: PropTypes.bool,
    disabledEdit: PropTypes.bool,
};

export const DeleteButtonCell = ({ onDelete, modelId, disabled = false }) => (
    <TableCell className="action-buttons">
        <Button
            renderIcon={TrashCan}
            kind="ghost"
            size="sm"
            iconDescription="Delete"
            onClick={() => onDelete(modelId, 1)}
            disabled={disabled}
            tooltipPosition="left"
            hasIconOnly
            aria-label={`delete ${disabled ? 'disabled' : ''}`}
        />
    </TableCell>
);
DeleteButtonCell.propTypes = {
    modelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onDelete: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
