import axios from 'axios';

/**
 *
 * @param {'mds' | 'cmd' | 'ilc'} slug url slug for the filter type
 * @returns {Promise<{
 *      success: boolean;
 *      data: any[];
 *      error: Error | null;
 * }>}
 */
export const getFilters = async (slug = 'mds') => {
    try {
        const { data } = await axios.post(`/api/fast-api/filters/${slug}`, null);
        return {
            success: true,
            data,
            error: null,
        };
    } catch (error) {
        console.error(error);
        return {
            success: false,
            data: [],
            error,
        };
    }
};
