import React from 'react';
import {
    SkeletonText,
    Button,
    Table,
    TableHead,
    TableHeader,
    TableBody,
    TableRow,
    TableSelectRow,
    TableCell,
} from '@carbon/react';
import { Edit, TrashCan, Replicate } from '@carbon/react/icons';

export const MxSelectionTable = ({
    columns,
    models,
    onEdit,
    onDelete,
    onSelect,
    onClone,
    selectedId,
    disabledEditButtons,
    isLoading,
    disabled,
}) => {
    return (
        <Table data-testid="mx-selection-table">
            <TableHead>
                <TableRow>
                    {columns.map(({ header, key }) => (
                        <TableHeader id={header} key={`${header}_${key}`}>
                            {header}
                        </TableHeader>
                    ))}
                </TableRow>
            </TableHead>
            {isLoading ? (
                <TableBody data-testid="loading-body">
                    {Array.from(Array(5)).map((r, idx) => (
                        <TableRow key={`loading_row_${idx}`}>
                            {columns.map(({ header, key }) => (
                                <TableCell id={`loading_${header}`} key={`loading_${header}_${key}`}>
                                    <SkeletonText />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            ) : (
                <TableBody>
                    {models.map((model, idx) => (
                        <TableRow
                            id={`${model.id}_${model.CMD}`}
                            key={`${model.id}_${model.CMD}`}
                            data-testid="mx-selection-row"
                        >
                            {columns.map(({ key }, j) => (
                                <React.Fragment key={`${model.id}_${key}_${j}`}>
                                    {key === 'radio' ? (
                                        <TableSelectRow
                                            id={`${model.CMD}_${key}_${idx}`}
                                            ariaLabel="Select model for calculation"
                                            disabled={disabled}
                                            checked={model.id === selectedId}
                                            onSelect={() => onSelect(model, idx)}
                                            name={model.CMD || 'select'}
                                            radio
                                        />
                                    ) : null}
                                    {key === 'edit' && (
                                        <>
                                            {model.EDITABLE === 0 ? (
                                                <TableCell
                                                    className="action-buttons ms-auto"
                                                    style={{
                                                        height: 'inherit',
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <Button
                                                            renderIcon={Replicate}
                                                            kind="ghost"
                                                            size="sm"
                                                            iconDescription="Copy Model"
                                                            data-testid="replicate-button"
                                                            onClick={() => onClone(model, idx)}
                                                            tooltipPosition="left"
                                                            hasIconOnly
                                                            disabled={disabledEditButtons}
                                                        />
                                                    </div>
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    className="action-buttons ms-auto"
                                                    style={{
                                                        height: 'inherit',
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <Button
                                                            renderIcon={Edit}
                                                            kind="ghost"
                                                            size="sm"
                                                            iconDescription="Edit Model"
                                                            data-testid="edit-button"
                                                            onClick={() => onEdit(model, idx)}
                                                            tooltipPosition="left"
                                                            hasIconOnly
                                                            disabled={disabledEditButtons || disabled}
                                                        />
                                                        <Button
                                                            renderIcon={TrashCan}
                                                            kind="ghost"
                                                            size="sm"
                                                            iconDescription="Delete Model"
                                                            data-testid="delete-button"
                                                            onClick={() => onDelete(model, idx)}
                                                            tooltipPosition="left"
                                                            hasIconOnly
                                                            disabled={
                                                                disabledEditButtons ||
                                                                models.length === 1 ||
                                                                model.STATUS === 'APPROVED' ||
                                                                disabled
                                                            }
                                                        />
                                                    </div>
                                                </TableCell>
                                            )}
                                        </>
                                    )}
                                    {key !== 'radio' && key !== 'edit' ? (
                                        <TableCell id={`${model.CMD}_${key}_${model.id}`}>{model[key]}</TableCell>
                                    ) : null}
                                </React.Fragment>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            )}
        </Table>
    );
};
