import { useEffect, useState } from 'react';
import { Button, Tab, TabList, TabPanel, TabPanels, Tabs, Theme } from '@carbon/react';
import { useSetAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { CARBON_THEME, CUSTOM_THEME } from '../../wizard/constants';
import { Navigation } from '../../../common';
import { TimeoutAlert } from '../../../common/TimeoutAlert';
import { useFetchAllUsers } from '../../wizard/modals/shareScenario/useFetchAllUsers';
import { AircrewModelsTab } from './AircrewModelsTab';
import { MxModelsTab } from './MxModelsTab';
import { gsFiltersAtom } from '../../wizard/steps/generalSettings/GeneralSettingsAtoms';

import './CreateModels.scss';

export const CreateModels = () => {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const setGsFilters = useSetAtom(gsFiltersAtom);

    useEffect(() => {
        setGsFilters({ CMD: '', MDS: '', ILC: '' });
    }, [activeIndex, setGsFilters]);

    useFetchAllUsers();

    return (
        <Theme theme={localStorage.getItem('theme') === CUSTOM_THEME.DARK ? CARBON_THEME.G90 : CARBON_THEME.WHITE}>
            <div className="wizard" style={{ minHeight: '100vh' }}>
                <Navigation />
                <div className="manage-models-content">
                    <Button className="back-to-admin-btn" onClick={() => navigate('/admin')}>
                        Back to Admin
                    </Button>
                    <Tabs onChange={({ selectedIndex }) => setActiveIndex(selectedIndex)}>
                        <TabList aria-label="List of tabs" contained>
                            <Tab data-testid="ops-models-tab">Aircrew Models</Tab>
                            <Tab data-testid="mx-models-tab">Maintenance Models</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel aria-label="ops-model-tab">
                                {activeIndex === 0 ? <AircrewModelsTab activeIndex={activeIndex} /> : null}
                            </TabPanel>
                            <TabPanel aria-label="mx-model-tab">
                                {activeIndex === 1 ? <MxModelsTab activeIndex={activeIndex} /> : null}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </div>
            </div>
            <TimeoutAlert />
        </Theme>
    );
};
