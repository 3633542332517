import { useState, useCallback, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';

import { gsFiltersAtom } from '../../steps/generalSettings/GeneralSettingsAtoms';
import { mxScenarioAtom } from '../../../../hooks/mxAtoms';
import { deleteMxModel, loadMxModels } from '../../maintenanceApiCalls';
import { useFocusAtom } from '../../../../hooks';
import { scenarioAtom } from '../../../../hooks/wizardAtoms';

/**
 *
 * @param {boolean} open
 * @returns {{
 *  isLoading: boolean,
 *  ftModels: any[]
 *  milModels: any[],
 *  fetchModelSummaryData: () => Promise<void>,
 *  handleDeleteModel: (model: any) => Promise<void>
 * }}
 */
export const useFetchModelSummaryData = (open) => {
    const [gsFilters] = useAtom(gsFiltersAtom);
    const [isLoading, setIsLoading] = useState(false);
    const [ftModels, setFtModels] = useState([]);
    const [milModels, setMilModels] = useState([]);
    const { MDS = '', CMD = '', ILC = '' } = gsFilters;

    const fetchModelSummaryData = useCallback(async () => {
        try {
            const params = CMD !== '' && ILC !== '' ? { mds: MDS, cmd: CMD, ilc: ILC } : { mds: MDS };
            const { fulltimeData, militaryData } = await loadMxModels(params);
            setMilModels(militaryData);
            setFtModels(fulltimeData);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [CMD, ILC, MDS]);

    useEffect(() => {
        if (open && MDS.length > 0) {
            setIsLoading(true);
            fetchModelSummaryData();
        }
    }, [open, MDS, fetchModelSummaryData]);

    const handleDeleteModel = async (model, modelType) => {
        try {
            const { isOk } = await deleteMxModel(model.MX_ID);

            if (isOk) {
                modelType === 'full-time'
                    ? setFtModels(ftModels.filter((curr) => curr.id !== model.id))
                    : setMilModels(milModels.filter((curr) => curr.id !== model.id));
            }
            return { isOk: true };
        } catch (error) {
            console.error(error);
            return { isOk: false };
        }
    };

    return {
        isLoading,
        ftModels,
        milModels,
        fetchModelSummaryData,
        handleDeleteModel,
    };
};

/**
 *
 * @param {boolean} open
 * @param {any[]} models
 * @param {"full-time" | "military"} modelType
 * @param {boolean} isLoading
 * @returns {{
 *  selectedId: number,
 *  handleSelection: (model: any) => void
 * }}
 */
export const useMxScenarioSelection = (open, models, modelType, isLoading) => {
    const [selectedModel, setSelectedModel] = useState({ ft: null, mil: null });
    const [selectedMxId] = useFocusAtom(scenarioAtom, modelType === 'full-time' ? 'ft_mx_id' : 'mil_mx_id');
    const modelKey = modelType === 'full-time' ? 'ft' : 'mil';

    const [mxScenario] = useAtom(mxScenarioAtom);
    const mxRef = useRef(mxScenario);

    // Keep mxScenario ref current
    mxRef.current = mxScenario;

    const modelsRef = useRef(models);
    modelsRef.current = models;

    const selectedModelRef = useRef(selectedModel);
    selectedModelRef.current = selectedModel;

    useEffect(() => {
        // Set the selected id to a model id if there is one in the scenario
        if (open && !isLoading && (selectedModelRef.current.ft === null || selectedModelRef.current.mil === null)) {
            const result = modelsRef.current.find((model) => model.MX_ID === selectedMxId);
            if (result) {
                setSelectedModel((prev) => ({ ...prev, [modelKey]: result }));
            }
        }
    }, [open, isLoading, selectedMxId, modelKey]);

    const handleSelection = (model) => {
        setSelectedModel((prev) => ({ ...prev, [modelKey]: model }));
    };

    return {
        handleSelection,
        selectedModel,
    };
};
