import React, { useState, useEffect, useCallback } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { ComboBox } from '@carbon/react';

import {
    isScenarioDialogOpenAtom,
    scenarioAtom,
    insertScenarioApiDataAtom,
    isShareScenarioOpenAtom,
    scenarioIsLoadingAtom,
    selectedMPESAtom,
    MPESDefault,
} from '../../hooks/wizardAtoms';
import { UpdateScenarioDialog } from '../../pages/wizard/modals/updateScenarioDialog/UpdateScenarioDialog';
import { useCloneAdjustmentDataToScenario } from '../../pages/wizard/steps/generalSettings/useCloneAdjustmentDataToScenario';
import {
    getScenario,
    createScenario,
    saveScenario,
    getAllScenarios,
    deleteScenario,
} from '../../pages/wizard/scenarioApiCalls';
import {
    useSaveScenarioIdInLocalStorage,
    useUpdateScenarioOnChange,
    scenarioDropdownFilter,
} from './scenarioDropdownHooks';
import { useClearMxOpsScenarios, useCurrentStep } from '../../hooks';
import { ShareScenario } from '../../pages/wizard/modals/shareScenario/ShareScenario';
import { stepOrder } from '../../pages/wizard/steps';
import { Banner } from '../../pages/wizard/steps/opsMx/Banner';
import { useTFABanner } from '../../hooks/useTFABanner';

export const ScenarioDropdown = () => {
    const [list, setList] = useState(/** @type {Scenario[]}*/ ([]));
    const [isLoading, setIsLoading] = useState(false);
    const [filterOn, setFilterOn] = useState(false);
    const [openShare, setOpenShare] = useAtom(isShareScenarioOpenAtom);
    const [currentScenario, setCurrentScenario] = useAtom(scenarioAtom);
    const [isScenarioDialogOpen, setIsScenarioDialogOpen] = useAtom(isScenarioDialogOpenAtom);
    const insertScenarioApiData = useSetAtom(insertScenarioApiDataAtom);
    const clearMxOpsScenarios = useClearMxOpsScenarios();
    const showTFABanner = useTFABanner();
    const setScenarioIsLoading = useSetAtom(scenarioIsLoadingAtom);
    const setSelectedMPES = useSetAtom(selectedMPESAtom);

    const pathOrder = stepOrder.map((step) => step.path);
    const currentStep = useCurrentStep(pathOrder);

    useSaveScenarioIdInLocalStorage(currentScenario?.scenario_id);

    const cloneAdjustmentDataToScenario = useCloneAdjustmentDataToScenario();

    const fetchScenarioList = useCallback(async () => {
        setIsLoading(true);
        const { isOk, scenarios } = await getAllScenarios();
        setIsLoading(false);
        return {
            isOk,
            scenarios,
        };
    }, []);

    const createEmptyScenario = useCallback(
        async (name = 'Default') => {
            try {
                const response = await createScenario({ scenario_name: name });
                const [createStatus] = response.data;
                if (createStatus === 200) {
                    const { scenarios } = await fetchScenarioList();
                    return scenarios;
                }
            } catch (error) {
                console.error('Could not create scenario');
            }
            return [];
        },
        [fetchScenarioList]
    );

    // Fetch scenario information if current scenario id changed
    useUpdateScenarioOnChange({
        scenario_id: currentScenario?.scenario_id,
        fetchScenarioList,
        setList,
    });

    useEffect(() => {
        fetchScenarioList()
            .then(async ({ isOk, scenarios }) => {
                let scenariosToUpdate = scenarios;
                if (isOk && scenarios.length === 0) {
                    scenariosToUpdate = await createEmptyScenario();
                }
                if (scenariosToUpdate.length === 0) {
                    throw new Error('No scenarios');
                }

                setList(scenariosToUpdate);
                const lastScenarioId = window?.localStorage.getItem('scenario_id');
                let index = scenariosToUpdate.findIndex((scenario) => `${scenario.scenario_id}` === lastScenarioId);
                if (index === -1) {
                    index = 0;
                }
                const scenario = scenariosToUpdate[index];
                setScenarioIsLoading(true);
                getScenario(scenario.scenario_id).then(({ isOk, data }) => {
                    setScenarioIsLoading(false);
                    if (isOk) {
                        insertScenarioApiData({ ...scenario, ...data });
                    } else {
                        insertScenarioApiData(scenario);
                    }
                });
            })
            .catch(() => console.error('Could not fetch scenarios'));
    }, [fetchScenarioList, createEmptyScenario, insertScenarioApiData]);

    const handleSelectScenario = ({ selectedItem }) => {
        if (selectedItem) {
            const { scenario_id, scenario_name, LAST_MODIFIED } = selectedItem;
            if (scenario_id !== currentScenario?.scenario_id) {
                insertScenarioApiData({
                    scenario_id,
                    scenario_name,
                    LAST_MODIFIED,
                });
                setSelectedMPES(MPESDefault);
            }
        }
    };

    const handleCloseScenarioDialog = () => setIsScenarioDialogOpen(false);

    const handleCreateScenario = async (name) => {
        try {
            setIsLoading(true);

            const clonedScenario = cloneAdjustmentDataToScenario();
            const requestBody = {
                // save current scenario state for new scenario
                // eg save generalSettings
                ...clonedScenario,
                scenario_name: name,
            };
            delete requestBody.scenario_id;

            await createScenario(requestBody, currentScenario.scenario_id);

            // Update scenario list
            const { scenarios } = await fetchScenarioList();
            const newScenario = scenarios.find((scenario) => scenario.scenario_name === name);
            setScenarioIsLoading(true);
            const { isOk, data: apiScenario } = await getScenario(newScenario.scenario_id);
            setScenarioIsLoading(false);
            if (isOk) {
                setList(scenarios);
                insertScenarioApiData({ ...newScenario, ...apiScenario });
                handleCloseScenarioDialog();
            }
        } catch (error) {
            console.error('Could not create scenario');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateScenario = async (/** @type {string} */ name) => {
        try {
            setIsLoading(true);
            const updatedScenario = {
                scenario_id: currentScenario.scenario_id,
                scenario_name: name,
            };
            const clonedScenario = await cloneAdjustmentDataToScenario();
            await clearMxOpsScenarios(clonedScenario);

            const { data } = await saveScenario({
                ...clonedScenario,
                ...updatedScenario,
            });
            const [updateStatus] = data;

            setScenarioIsLoading(true);
            const { isOk, data: updatedScenarioFromApi } = await getScenario(currentScenario.scenario_id);
            setScenarioIsLoading(false);
            if (updateStatus === 200 && isOk) {
                // Keep updated name since api does not return scenario_name
                updatedScenarioFromApi.scenario_name = name;
                updatedScenarioFromApi.scenario_id = currentScenario.scenario_id;
            }

            const currentList = [...list];
            const index = currentList.findIndex((item) => item.scenario_id === currentScenario.scenario_id);
            if (index > -1) {
                currentList[index] = updatedScenario;
            }

            setCurrentScenario(updatedScenarioFromApi);
            setList(currentList);
            handleCloseScenarioDialog();
        } catch (error) {
            console.error('Could not update scenario');
        } finally {
            setIsLoading(false);
            if (currentStep.slug === 'ops' || currentStep.slug === 'mx') window.location.reload();
        }
    };

    const handleDeleteScenario = async () => {
        try {
            setIsLoading(true);
            const { isOk } = await deleteScenario(currentScenario.scenario_id);
            if (!isOk) {
                throw new Error('Could not delete scenario');
            }

            const filteredList = list.filter((item) => item.scenario_name !== currentScenario.scenario_name);

            if (filteredList.length > 0) {
                setList(filteredList);
                insertScenarioApiData(filteredList[0] ?? {});
            } else {
                // Case all scenarios were deleted
                const newList = await createEmptyScenario();
                setList(newList);
                insertScenarioApiData(newList[0] ?? {});
            }
            handleCloseScenarioDialog();
        } catch (error) {
            console.error('Could not delete scenario');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="scenario-dropdown">
            <div data-testid="scenario-dropdown-container">
                <ComboBox
                    className="scenario-dropdown-picker"
                    titleText="Scenario:"
                    id="scenario-dropdown-picker"
                    label=""
                    selectedItem={currentScenario}
                    type="inline"
                    aria-label="scenario dropdown"
                    size="lg"
                    items={list}
                    data-testid="scenario-dropdown"
                    itemToElement={(item) => (
                        <ScenarioDropdownItem
                            name={item.scenario_name}
                            updated={item.LAST_MODIFIED}
                            status={item.status}
                        />
                    )}
                    itemToString={(item) =>
                        item
                            ? item.LAST_MODIFIED
                                ? `${item.scenario_name} [Updated: ${item.LAST_MODIFIED}]`
                                : item.scenario_name
                            : ''
                    }
                    shouldFilterItem={filterOn ? scenarioDropdownFilter : undefined}
                    onInputChange={(e) => setFilterOn(true)}
                    onToggleClick={() => setFilterOn(false)}
                    onFocus={() => setFilterOn(false)}
                    onChange={handleSelectScenario}
                />
            </div>
            <UpdateScenarioDialog
                open={isScenarioDialogOpen}
                onClose={handleCloseScenarioDialog}
                onSubmitNew={handleCreateScenario}
                onSubmitUpdate={handleUpdateScenario}
                onDelete={handleDeleteScenario}
                currentScenarioName={currentScenario.scenario_name}
                currentScenarioId={currentScenario.scenario_id}
                disabled={isLoading}
                list={list}
            />
            <ShareScenario open={openShare} onClose={() => setOpenShare(false)} scenario={currentScenario} />
            {showTFABanner ? <Banner type="tfa" wrapperClassName="tfa-banner-wrapper" className="tfa-banner" /> : null}
        </div>
    );
};

export function ScenarioDropdownItem({ name, updated, status }) {
    return (
        <div>
            {name}
            <div>
                {`[${status}]`}
                {updated ? <span className="ms-2">{`[Updated: ${updated}]`}</span> : null}
            </div>
        </div>
    );
}
