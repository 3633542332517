import React, { useCallback, useEffect, useState } from 'react';
import { useSetAtom } from 'jotai';

import { Add } from '@carbon/react/icons';
import { Button, ContentSwitcher, InlineNotification, Loading, Modal, Switch } from '@carbon/react';
import { loadMxModels } from '../../wizard/maintenanceApiCalls';
import { ModelFilters } from '../ManageModels/ModelFilters';
import { getFilters } from './createModelsApi';
import { useFocusAtom } from '../../../hooks';
import { CMDFiltersAtom, gsFiltersAtom } from '../../wizard/steps/generalSettings/GeneralSettingsAtoms';
import { useAtom } from 'jotai';
import { useFetchModelSummaryData, useMxScenarioSelection } from '../../wizard/modals/mxEquations/mxEquationsHooks';
import { paiReqAtom, updateMxEquationsAtom } from '../../../hooks/mxAtoms';
import { isConditionalEqnsOpenAtom } from '../../wizard/modals/mxEquations/EditMxEquations/advancedMxEquations/useConditionalEqns';
import { useMilitaryFullTimeRequirment } from '../../wizard/modals/mxEquations/EditMxEquations/useMilitaryFullTimeRequirement';
import { updateModelId } from '../../wizard/scenarioApiCalls';
import { MxSelectionTable } from '../../wizard/modals/mxEquations/MxSelectionTable';
import { ConditionalEqns } from '../../wizard/modals/mxEquations/EditMxEquations/advancedMxEquations/ConditionalEqns';
import { EditMxEquations } from '../../wizard/modals/mxEquations/EditMxEquations/EditMxEquations';
import '../../wizard/modals/mxEquations/MxEquations.scss';

const mxModelsProps = {};
const columns = [
    { header: 'MDS', key: 'MDS' },
    { header: 'CMD', key: 'CMD' },
    { header: 'ILC', key: 'ILC' },
    { header: 'Model Name', key: 'MODEL_NAME' },
    { header: 'Mission Type', key: 'MODEL_TYPE' },
    { header: 'Option', key: 'MODEL_OPTION' },
    { header: 'Source', key: 'SOURCE' },
    { header: 'POC', key: 'POC' },
    { header: '', key: 'edit' },
];
const scenario_id = -1;

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof mxModelsProps>>}
 * @returns {React.ReactElement}
 */
export const MxModelsTab = ({ activeIndex }) => {
    const [mdsList, setMdsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [mdsFilter, setMdsFilter] = useFocusAtom(gsFiltersAtom, 'MDS');
    const [modelCmd, setModelCmd] = useState('');
    const updateCmdFilters = useSetAtom(CMDFiltersAtom);

    const populateMDS = useCallback(async function () {
        setIsLoading(true);
        const { data, success } = await getFilters('mds');
        if (success) {
            setMdsList(data);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (mdsList.length < 1) {
            populateMDS();
        }
    }, [activeIndex, mdsList.length, populateMDS]);

    useEffect(() => {
        async function fetchCmds() {
            const { data, success } = await getFilters('cmd');
            if (success) {
                updateCmdFilters(data);
            }
        }
        fetchCmds();
    }, [updateCmdFilters]);

    const updateMxEquations = useSetAtom(updateMxEquationsAtom);
    const setPaiReq = useSetAtom(paiReqAtom);
    const [isConditionalEqnsOpen] = useAtom(isConditionalEqnsOpenAtom);

    const [openEditModel, setOpenEditModel] = useState(false);
    const [editMxId, setEditMxId] = useState(null);
    const [editOption, setEditOption] = useState(null);
    const [action, setAction] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [modelsUpdated, setModelsUpdated] = useState(null);
    const [isActiveCmd, setisActiveCmd] = useState(false);

    const { isDisabled, handleToggleRqmt, fullRequire } = useMilitaryFullTimeRequirment();

    const {
        isLoading: modelSummaryIsLoading,
        ftModels,
        milModels,
        handleDeleteModel,
        fetchModelSummaryData,
    } = useFetchModelSummaryData(true);

    const showFulltimeModels = fullRequire === 'full-time' ? ftModels : milModels;

    const { selectedModel, handleSelection } = useMxScenarioSelection(true, showFulltimeModels, fullRequire, isLoading);

    const selectedModelId = fullRequire === 'full-time' ? selectedModel?.ft : selectedModel?.mil;

    const handleOpenUpdateModel = async (model, idx) => {
        if (model) {
            setAction('edit');
            setEditOption(model.MODEL_OPTION);
            setEditMxId(model.MX_ID);
            setisActiveCmd(model.CMD !== 'ANG' && model.CMD !== 'AFR');
            setModelCmd(model.CMD);
            updateMxEquations({
                l_id: model.MX_ID,
                source: model.SOURCE,
                modelName: model.MODEL_NAME,
                poc: model.POC,
                notes: model.NOTES,
            });
        } else {
            setAction('add');
            setEditOption(null);
            setEditMxId(null);
            updateMxEquations({
                l_id: '',
                source: '',
                modelName: '',
                poc: '',
                notes: '',
            });
            setPaiReq({});
            setModelCmd('');
        }
        setOpenEditModel(true);
    };

    const handleOpenCloneModel = async (model, idx) => {
        const date = new Date().toLocaleString('en-us', {
            timeZone: 'UTC',
        });
        setAction('copy');
        setEditOption(model.MODEL_OPTION);
        setEditMxId(model.MX_ID);
        updateMxEquations({
            l_id: model.MX_ID,
            source: model.SOURCE,
            modelName: model.MODEL_NAME + ' ' + date,
            poc: model.POC,
            notes: model.NOTES,
        });
        setOpenEditModel(true);
        setisActiveCmd(model.CMD !== 'ANG' && model.CMD !== 'AFR');
        setModelCmd(model.CMD);
    };

    const handleOpenDeleteConfirmation = (model) => {
        setDeleteConfirmation(model);
    };
    const closeDeleteConfirmation = () => {
        setDeleteConfirmation(null);
    };

    // const handleSaveAndSubmit = async () => {
    //     const updateMxModelBody = {
    //         ft_mx_id: selectedModel?.ft.MX_ID,
    //     };
    //     if (!isDisabled && selectedModel?.mil) {
    //         updateMxModelBody.mil_mx_id = selectedModel?.mil.MX_ID;
    //     }
    //     try {
    //         const response = await updateModelId(scenario_id, updateMxModelBody);
    //         if (response.data[0] === 200) {
    //             // TODO: update
    //             console.log('success');
    //         }
    //     } catch (error) {
    //         console.error('Could not update scenario', error);
    //     }
    //     // onClose();
    // };

    const inlineNotificationTitle = {
        update: `${modelsUpdated?.modelName} has been updated.`,
        delete: `${modelsUpdated?.modelName} has been deleted.`,
        create: `A new model has been created: ${modelsUpdated?.modelName}.`,
    };

    return (
        <div className="mx-equations">
            <ModelFilters
                cmdList={[]}
                cmdFilter={''}
                mdsFilter={mdsFilter}
                mdsList={mdsList}
                onSelect={({ mds }) => {
                    if (mds) {
                        setMdsFilter(mds);
                    }
                }}
            />
            {mdsFilter ? (
                <div className="form-input-container">
                    <ContentSwitcher
                        style={{
                            minWidth: 'fit-content',
                            width: 'fit-content',
                            marginRight: 'auto',
                        }}
                        className="mb-3"
                        selectedIndex={fullRequire === 'military' ? 1 : 0}
                        onChange={handleToggleRqmt}
                    >
                        <Switch
                            name="full-time"
                            text="Full Time RQMT"
                            disabled={openEditModel}
                            style={{
                                minWidth: 'fit-content',
                                width: 'fit-content',
                            }}
                        />
                        <Switch
                            name="military"
                            text="Military RQMT"
                            disabled={openEditModel}
                            style={{
                                minWidth: 'fit-content',
                                width: 'fit-content',
                            }}
                        />
                    </ContentSwitcher>
                    <div>
                        <MxSelectionTable
                            columns={columns}
                            models={showFulltimeModels}
                            onEdit={handleOpenUpdateModel}
                            onClone={handleOpenCloneModel}
                            onDelete={handleOpenDeleteConfirmation}
                            onSelect={handleSelection}
                            isLoading={isLoading}
                            disabledEditButtons={openEditModel}
                            selectedId={selectedModelId?.MX_ID}
                            // disabled
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3 mb-3 ms-auto">
                        <div style={{ width: 'fit-content' }}>
                            {modelsUpdated && (
                                <InlineNotification
                                    title=""
                                    subtitle={inlineNotificationTitle[modelsUpdated?.type]}
                                    kind={modelsUpdated?.type === 'delete' ? 'error' : 'success'}
                                    onClose={() => setModelsUpdated(null)}
                                />
                            )}
                        </div>
                        <Button
                            kind="primary"
                            size="md"
                            renderIcon={Add}
                            iconDescription="plus sign"
                            onClick={() => handleOpenUpdateModel()}
                            disabled={openEditModel}
                        >
                            Add New Model
                        </Button>
                    </div>
                    {openEditModel ? (
                        <>
                            <div className="divider" />
                            <EditMxEquations
                                open
                                isAdminPage
                                handleCloseEdit={async (refetch) => {
                                    if (refetch) {
                                        await fetchModelSummaryData();
                                    }
                                    setAction(null);
                                    setOpenEditModel(false);
                                }}
                                mxModels={showFulltimeModels}
                                modelType={fullRequire}
                                editOption={editOption}
                                mxId={editMxId}
                                action={action}
                                isActiveCmd={isActiveCmd}
                                setisActiveCmd={setisActiveCmd}
                                modelCmd={modelCmd}
                                setModelsUpdated={setModelsUpdated}
                            />
                        </>
                    ) : null}
                </div>
            ) : null}

            {deleteConfirmation ? (
                <Modal
                    data-testid="delete-mx-model-confirmation-modal"
                    open={deleteConfirmation !== null}
                    modalHeading="Are you sure you want to delete this model?"
                    danger
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                    onRequestClose={closeDeleteConfirmation}
                    onRequestSubmit={async () => {
                        const { isOk } = await handleDeleteModel(deleteConfirmation, fullRequire);
                        if (isOk) {
                            setModelsUpdated({
                                type: 'delete',
                                modelName: showFulltimeModels.find((model) => model.MX_ID === deleteConfirmation.MX_ID)
                                    ?.MODEL_NAME,
                            });
                        }
                        if (deleteConfirmation.MX_ID === selectedModelId?.MX_ID) {
                            handleSelection(showFulltimeModels[0]);
                        }
                        closeDeleteConfirmation();
                    }}
                />
            ) : null}
            {isConditionalEqnsOpen ? <ConditionalEqns /> : null}
        </div>
    );
};

MxModelsTab.propTypes = mxModelsProps;
