import axios from 'axios';
import { MPESDefault } from '../../hooks/wizardAtoms';

/**
 *
 * @param {any} body
 * @returns {Promise<LibAxios>}
 */
export const createMxModel = async (body, scenarioId) => {
    return axios.post('/api/fast-api/maintenance/create', body, {
        params: { scenario_id: scenarioId },
    });
};

/**
 *
 * @param {any} body
 * @returns {Promise<LibAxios>}
 */
export const saveMxModel = async (body, scenarioId) => {
    return axios.post('/api/fast-api/maintenance/save', body, {
        params: { scenario_id: scenarioId },
    });
};

/**
 *
 * @param {number} mx_id
 * @param {boolean} archive
 * @returns
 */
export const deleteMxModel = async (mx_id, archive = true) => {
    try {
        await axios.post('/api/fast-api/maintenance/delete', null, {
            params: { mx_id, archive },
        });
        return {
            isOk: true,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            error,
        };
    }
};

/**
 *
 * @param {{
 *  UNIT: string,
 *  MX_UNIT: string,
 *  PEC: string,
 *  RIC: string,
 *  PAI_TYPE: string,
 *  PAI_QTY: number
 *  NTI: number,
 *  MX_ID: number,
 * }} body
 * @param {number} scenario_id
 */
export const updateMxQuantity = async (
    scenario_id,
    body = {
        UNIT: null,
        MX_UNIT: null,
        PEC: null,
        RIC: null,
        PAI_TYPE: null,
        PAI_QTY: null,
        NTI: null,
        MX_ID: null,
    }
) => {
    return axios.post('/api/fast-api/maintenance/update_qty', body, {
        params: { scenario_id },
    });
};

/**
 *
 * @param {{
 *      cmd: string;
 *      mds: string;
 *      ilc: string;
 * }} params
 * @returns
 */
export const loadMxModels = async ({ mds, cmd, ilc }) => {
    try {
        const { data } = await axios.post(`/api/fast-api/maintenance/load_models`, {}, { params: { mds, cmd, ilc } });

        const mappedFulltimeData = data.FULLTIME.map((modelData) => {
            const newData = {
                ...modelData,
                MODEL_TYPE: modelData.MODEL_TYPE.toUpperCase(),
                id: modelData.MX_ID,
            };
            return newData;
        });

        const mappedMiltaryData = data.MILITARY.map((modelData) => {
            const newData = {
                ...modelData,
                MODEL_TYPE: modelData.MODEL_TYPE.toUpperCase(),
                id: modelData.MX_ID,
            };
            return newData;
        });

        return {
            isOk: true,
            fulltimeData: mappedFulltimeData,
            militaryData: mappedMiltaryData,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            fulltimeData: [],
            militaryData: [],
            error,
        };
    }
};

/**
 *
 * @param {string | number} scenarioId
 * @returns
 */
export const fetchMxAsis = async (scenarioId, mpesYear) => {
    try {
        const params = { scenario_id: scenarioId };
        if (mpesYear && mpesYear !== MPESDefault) {
            params.mpes_year = mpesYear;
        }
        const { data } = await axios.post(`/api/fast-api/maintenance/asis`, null, { params });

        let isMissing = false;
        const facTitles = {};
        const pecTitles = {};
        const ricTitles = {};
        const formattedData = data.ASIS.map((row) => {
            isMissing = isMissing || row.PARENT.MISSING;
            pecTitles[row.PARENT.PEC] = row.PARENT.PEC_TITLE;
            return {
                ...row,
                CHILDREN: row.CHILDREN.map((child) => {
                    isMissing = isMissing || child.MISSING;
                    facTitles[child.FAC] = child.FAC_TITLE;
                    ricTitles[child.RIC] = child.RIC_TITLE;
                    return {
                        ...child,
                        parentId: `mxAsis_${row.PARENT.ID}`,
                        id: `mxAsis_${child.ID}`,
                    };
                }),
                id: `mxAsis_${row.PARENT.ID}`,
            };
        });
        return {
            isOk: true,
            data: formattedData,
            dropdowns: data.DROPDOWN,
            tooltips: {
                facTitles,
                pecTitles,
                ricTitles,
            },
            isMissing,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            data: [],
            dropdowns: {
                FAC: [],
                API: [],
                MSI: [],
            },
            tooltips: {
                facTitles: {},
                pecTitles: {},
                ricTitles: {},
            },
            isMissing: false,
            error,
        };
    }
};

/**
 *
 * @param {string | number} scenarioId
 * @returns
 */
export const fetchMxTobe = async (scenarioId) => {
    try {
        const { data } = await axios.post(`/api/fast-api/maintenance/tobe`, null, {
            params: { scenario_id: scenarioId },
        });

        const facTitles = {};
        const pecTitles = {};
        const ricTitles = {};
        let isMissing = false;
        let isUnconventional = false;
        const formattedData = data.map((row, parentIdx) => {
            pecTitles[row.PARENT.PEC] = row.PARENT.PEC_TITLE;
            isMissing = isMissing || row.PARENT.MISSING;
            isUnconventional = isUnconventional || row.PARENT.UNCONVENTIONAL;
            return {
                ...row,
                CHILDREN: row.CHILDREN.map((child) => {
                    pecTitles[child.PARENT.PEC] = child.PARENT.PEC_TITLE;
                    isMissing = isMissing || child.PARENT.MISSING;
                    isUnconventional = isUnconventional || child.PARENT.UNCONVENTIONAL;
                    return {
                        ...child,
                        PARENT: {
                            ...child.PARENT,
                            QTY: `${Math.round(parseFloat(child.PARENT.QTY))}`,
                        },
                        CHILDREN: child.CHILDREN.map((secondChild) => {
                            facTitles[secondChild.FAC] = secondChild.FAC_TITLE;
                            ricTitles[secondChild.RIC] = secondChild.RIC_TITLE;
                            isMissing = isMissing || secondChild.MISSING;
                            isUnconventional = isUnconventional || secondChild.UNCONVENTIONAL;
                            return {
                                ...secondChild,
                                id: `mxTobe_${secondChild.ID}`,
                                parentId: `mxTobe_${child.PARENT.ID}`,
                                mainParentIdx: parentIdx,
                                RIC: null,
                            };
                        }),
                        id: `mxTobe_${child.PARENT.ID}`,
                        parentId: `mxTobe_${row.PARENT.ID}`,
                        mainParentIdx: parentIdx,
                    };
                }),
                id: `mxTobe_${row.PARENT.ID}`,
            };
        });
        return {
            isOk: true,
            data: formattedData,
            tooltips: {
                facTitles,
                pecTitles,
                ricTitles,
            },
            isMissing,
            isUnconventional,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            data: [],
            tooltips: {
                facTitles: {},
                pecTitles: {},
                ricTitles: {},
            },
            isMissing: false,
            isUnconventional: false,
            error,
        };
    }
};

export const fetchMxFulltimeModel = async ({ cmd, ilc, mds, baseline }) => {
    try {
        const { data } = await axios.post('/api/fast-api/maintenance/load_fulltime_model', null, {
            params: {
                cmd,
                ilc,
                mds,
                baseline,
            },
        });
        const fulltimeModel = data[0];
        const workcentersData = fulltimeModel.WC_ID_TABLE.map(({ ID, ...wc }) => {
            // Remove ID and replace with lowercase id
            return {
                ...wc,
                id: `${ID}`,
            };
        });

        return {
            isOk: true,
            error: null,
            fulltimeModel,
            workcentersData,
        };
    } catch (error) {
        return {
            isOk: false,
            fulltimeModel: null,
            workcentersData: [],
            error,
        };
    }
};

/**
 * Fetches workcenter data for option A
 * @param {number} mxId
 * @param {string} ilc
 * @returns {Promise<{ isOk: boolean, error: Error | null, workcentersData: any[] }>}
 */
export const fetchMxModelDetailsOptionA = async (mxId, ilc) => {
    try {
        const { data } = await axios.post(
            '/api/fast-api/maintenance/load_option_a',
            {},
            { params: { mx_id: mxId, ilc } }
        );
        // eslint-disable-next-line no-unused-vars
        const workcentersData = data[0].WC_ID_TABLE.map(({ ID, ...wc }) => {
            // Remove ID and replace with lowercase id
            return {
                ...wc,
                id: `${ID}`,
            };
        });

        return {
            isOk: true,
            error: null,
            workcentersData,
        };
    } catch (error) {
        return {
            isOk: false,
            workcentersData: [],
            error,
        };
    }
};

/**
 *
 * @param {string | number} mxId
 * @returns {Promise<{ isOk: boolean, error: Error | null, data: any }>}
 */
export const fetchMxModelDetailsOptionB = async (mxId) => {
    const params = { mx_id: mxId };

    try {
        const { data } = await axios.post(`/api/fast-api/maintenance/load_option_b`, null, { params });

        const parseConditionalEqns = (arr) => {
            const format = (eqnData) => {
                try {
                    return eqnData
                        .map((eqn) => {
                            const variable = eqn.VARIABLE ?? eqn.variable;
                            const conditions = eqn.CONDITION ?? eqn.CONDITIONS ?? eqn.conditions;
                            const y = eqn.Y ?? eqn.y;
                            const updatedConditions = Array.isArray(conditions)
                                ? conditions.map((condition) => {
                                      let i = 0;
                                      let comparison = '';
                                      do {
                                          comparison += condition[i];
                                          i += 1;
                                      } while (isNaN(condition[i]));

                                      return {
                                          comparison,
                                          rhs: condition.slice(i),
                                      };
                                  })
                                : null;
                            return {
                                conditions: updatedConditions,
                                variable,
                                y,
                            };
                        })
                        .filter(({ variable, conditions, y }) => conditions !== null && variable !== undefined);
                } catch (error) {
                    console.error(error);
                    return eqnData;
                }
            };
            return arr.map((data, idx) => ({
                id: 'option_b_' + data.ID + '_' + idx,
                fac: data.FAC,
                osc: data.OSC,
                wc_title: data.WC_TITLE,
                afsc: data.AFSC,
                ...data,
                conditionalEqns:
                    data.CONDITIONAL_EQS && data.CONDITIONAL_EQS.length > 0 && data.CONDITIONAL_EQS !== 'null'
                        ? format(data.CONDITIONAL_EQS)
                        : null,
            }));
        };

        const missionType = data[0].MODEL_TYPE.toLowerCase();
        return {
            isOk: true,
            data: {
                maf: data[0].MAF,
                dataTable: parseConditionalEqns(data[0].WC_ID_TABLE),
                missionType,
            },
            error: null,
        };
    } catch (error) {
        console.error(error);
        return {
            isOk: false,
            data: {},
            error,
        };
    }
};

/**
 *
 * @param {{filter_data: {[key: string]: any}}} filterData
 * @param {number} scenario_id
 * @returns
 */
export const fetchTempMxAsis = async ({ filterData, scenario_id = 1 }) => {
    try {
        const { data } = await axios.post('/api/fast-api/maintenance/temp_session_asis', filterData, {
            params: { scenario_id },
        });

        let isMissing = false;
        const facTitles = {};
        const pecTitles = {};
        const ricTitles = {};
        const formattedData = data.map((row) => {
            isMissing = isMissing || row.PARENT.MISSING;
            pecTitles[row.PARENT.PEC] = row.PARENT.PEC_TITLE;
            return {
                ...row,
                CHILDREN: row.CHILDREN.map((child) => {
                    isMissing = isMissing || child.MISSING;
                    facTitles[child.FAC] = child.FAC_TITLE;
                    ricTitles[child.RIC] = child.RIC_TITLE;
                    return {
                        ...child,
                        parentId: `mxAsis_${row.PARENT.ID}`,
                        id: `mxAsis_${child.ID}`,
                    };
                }),
                id: `mxAsis_${row.PARENT.ID}`,
            };
        });

        return {
            isOk: true,
            data: formattedData,
            tooltips: {
                facTitles,
                pecTitles,
                ricTitles,
            },
            isMissing,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            data: [],
            tooltips: {
                facTitles: {},
                pecTitles: {},
                ricTitles: {},
            },
            isMissing: false,
            error,
        };
    }
};
