import React, { useCallback, useEffect, useState } from 'react';

import { Button, ContentSwitcher, InlineNotification, Loading, Modal, Switch } from '@carbon/react';
import {
    createAircrewModel,
    deleteAircrewModel,
    fetchAircrewModels,
    fetchOpsFullTimeEquation,
    updateAircrewModel,
} from '../../wizard/aircrewApiCalls';
import { ModelFilters } from '../ManageModels/ModelFilters';
import { getChildId, getParentId } from '../../wizard/modals/opsEquations/getOpsEquationsIds';
import { OpsSelectionTable } from '../../wizard/modals/opsEquations/opsSelectionTable/OpsSelectionTable';
import { getFilters } from './createModelsApi';
import { UpdateModel } from '../../wizard/modals/opsEquations/updateModel';
import { WlfEquations } from '../../wizard/modals/opsEquations/opsFulltimeEquation/WlfEquations';
import { FulltimeEquationInputs } from '../../wizard/modals/opsEquations/opsFulltimeEquation/FulltimeEquationInputs';
import { useWlfEquationsState } from '../../wizard/modals/opsEquations/opsFulltimeEquation/wlfEquationsState';
import { useFocusAtom } from '../../../hooks';
import { filtersAtom, gsFiltersAtom, MDSFiltersAtom } from '../../wizard/steps/generalSettings/GeneralSettingsAtoms';
import { useAtom, useSetAtom } from 'jotai';
import { adminCreateModelsPageAtom } from '../../wizard/modals/opsEquations/opsFulltimeEquation/FulltimeEquationDataTable';
import { DeleteFullTimeEqnModal } from '../../wizard/modals/opsEquations/DeleteFulltimeEqnModal';

const aircrewModelsProps = {};
const columns = [
    { header: 'MDS', key: 'MDS' },
    { header: 'CMD', key: 'CMD' },
    { header: 'Model Name', key: 'MODEL_NAME' },
    { header: 'Crew Ratio', key: 'CREW_RATIO' },
    { header: 'Crew Comp Enl', key: 'CREW_COMP_ENL' },
    { header: 'Crew Comp Off', key: 'CREW_COMP_OFF' },
    { header: 'Notes', key: 'NOTES' },
    { header: 'Source', key: 'SOURCE' },
    { header: 'POC', key: 'POC' },
    { header: 'AFSC', key: 'AFSC' },
    { header: 'FAC', key: 'FAC' },
    { header: 'Status', key: 'STATUS' },
    { header: '', key: 'edit' },
];
const scenario_id = -1;
const getInlineNotificationTitle = (type, modelName) => {
    return {
        update: `${modelName} has been updated.`,
        delete: `${modelName} has been deleted.`,
        create: `A new model has been created: ${modelName}.`,
    }[type];
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof aircrewModelsProps>>}
 * @returns {React.ReactElement}
 */
export const AircrewModelsTab = ({ activeIndex }) => {
    const [models, setModels] = useState([]);
    const [mdsList, setMdsList] = useState([]);
    const [cmdList, setCmdList] = useState([]);
    const [mdsFilter, setMdsFilter] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [fetchedData, setFetchedData] = useState(false);
    const [openDeleteFulltimeModel, setOpenDeleteFulltimeModel] = useState(null);
    const [aircrewType, setAircrewType] = useState('models');
    const [gsFiltersMds, setGsFiltersMds] = useFocusAtom(gsFiltersAtom, 'MDS');
    const updateMdsFilters = useSetAtom(MDSFiltersAtom);
    const {
        showUpdateModel,
        notification,
        notificationType,
        handleAddUpdatedModel,
        handleOpenUpdateModel,
        model,
        submitting,
        action,
        handleCloseUpdateModel,
        closeNotification,
    } = useUpdateModel(mdsFilter, () => {
        setFetchedData(false);
    });

    const {
        fulltimeEqnId,
        handleSubmitWlfChanges,
        isOpenWlfEquationsModal,
        setFulltimeEqnId,
        submitAllUpdatedFulltimeEquations,
    } = useFulltimeEquations({ open: !!gsFiltersMds, MDS: gsFiltersMds });

    const { deleteOpsId, setDeleteOpsId, setDeleteLevel, handleDelete } = useDeleteModal({ models, setModels }, () => {
        setFetchedData(false);
    });

    const populateMDS = useCallback(
        async function () {
            setIsLoading(true);
            const { data, success } = await getFilters('mds');
            if (success) {
                setMdsList(data);
                updateMdsFilters(data);
            }
            setIsLoading(false);
        },
        [updateMdsFilters]
    );

    const filteredSettings = useFilters();

    useEffect(() => {
        if (activeIndex === 0 && mdsList.length < 1) {
            populateMDS();
        }
    }, [activeIndex, mdsList.length, populateMDS]);

    useEffect(() => {
        async function fetchCmds() {
            const { data, success } = await getFilters('cmd');
            if (success) {
                setCmdList(data);
            }
        }
        fetchCmds();
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const { isOk, data } = await fetchAircrewModels({ mds: mdsFilter });
                setGsFiltersMds(mdsFilter);
                if (!isOk) {
                    throw new Error('Could not fetch model data');
                }
                const mappedData = data.map((modelData) => {
                    const modifiedData = { ...modelData };
                    const parentData = modelData.PARENT;
                    const childData = modelData.CHILDREN;
                    const parentKeys = ['ACR_ID', 'CMD', 'MDS', 'MODEL_NAME', 'POC', 'SOURCE'];

                    // add unique id to parent row
                    modifiedData.modelId = getParentId(parentData);
                    modifiedData.id = parentData.ID;

                    // add source and poc to parent row
                    modifiedData.PARENT.SOURCE = childData[0].SOURCE;
                    modifiedData.PARENT.POC = childData[0].POC;

                    // add unique id to each child row.
                    // remove common parent values from children
                    const newChildModelsData = childData.map((childModel) => {
                        const childId = getChildId(childModel, modifiedData.modelId);
                        const childProperties = Object.keys(childModel)
                            .filter((childKey) => parentKeys.includes(childKey) === false)
                            .reduce((a, b) => ({ ...a, [b]: childModel[b] }), {});
                        return {
                            ...childProperties,
                            id: childModel.ID,
                            childModelId: childId,
                            parentId: modifiedData.modelId,
                        };
                    });
                    modifiedData.CHILDREN = newChildModelsData;

                    return modifiedData;
                });
                setModels(mappedData);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        }
        if (mdsFilter && !fetchedData) {
            setFetchedData(true);
            setIsLoading(true);
            fetchData();
        }
    }, [mdsFilter, setGsFiltersMds, fetchedData]);

    return (
        <div data-testid="ops-model-tab-content">
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <ModelFilters
                        cmdList={[]}
                        cmdFilter={''}
                        mdsFilter={mdsFilter}
                        mdsList={mdsList}
                        onSelect={({ mds }) => {
                            setMdsFilter(mds);
                            setFetchedData(false);
                        }}
                    />
                    {models.length > 0 && mdsFilter !== '' && (
                        <div className="my-3">
                            <ContentSwitcher
                                style={{
                                    minWidth: 'fit-content',
                                    width: 'fit-content',
                                    marginRight: 'auto',
                                }}
                                className="mb-3"
                                selectedIndex={aircrewType === 'models' ? 0 : 1}
                                onChange={({ name }) => setAircrewType(name)}
                            >
                                <Switch
                                    name="models"
                                    text="Aircrew Models"
                                    style={{
                                        minWidth: 'fit-content',
                                        width: 'fit-content',
                                    }}
                                    data-testid="aircrew-models-switch"
                                />
                                <Switch
                                    name="fulltime-equations"
                                    text="Fulltime Equations"
                                    style={{
                                        minWidth: 'fit-content',
                                        width: 'fit-content',
                                    }}
                                    data-testid="fulltime-equations-switch"
                                />
                            </ContentSwitcher>
                            {aircrewType === 'models' ? (
                                <>
                                    <div className="mb-3">
                                        <OpsSelectionTable
                                            columns={columns}
                                            models={models}
                                            onEdit={handleOpenUpdateModel}
                                            onDelete={(modelId, level) => {
                                                setDeleteOpsId(modelId);
                                                setDeleteLevel(level);
                                            }}
                                            onSelect={() => {}}
                                            isLoading={isLoading && models?.length === 0}
                                            isAdminPage={true}
                                        />
                                    </div>

                                    {mdsFilter !== '' ? (
                                        <Button
                                            style={{ display: 'block' }}
                                            disabled={showUpdateModel}
                                            className="ms-auto"
                                            onClick={() => handleOpenUpdateModel()}
                                        >
                                            Add Model
                                        </Button>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <div className="divider mt-5" />
                                    <FulltimeEquationInputs
                                        open
                                        scenarioId={scenario_id}
                                        onOpenClick={setFulltimeEqnId}
                                        onlyUserCanEdit
                                        ilcList={filteredSettings('ILC')}
                                        onDeleteModel={setOpenDeleteFulltimeModel}
                                    />
                                    <WlfEquations
                                        open={!!isOpenWlfEquationsModal}
                                        onClose={() => setFulltimeEqnId(null)}
                                        onSubmit={handleSubmitWlfChanges}
                                        eqnId={fulltimeEqnId}
                                        scenarioId={scenario_id}
                                        disabled={false}
                                    />
                                    <DeleteFullTimeEqnModal
                                        openDeleteFulltimeModel={openDeleteFulltimeModel}
                                        setOpenDeleteFulltimeModel={setOpenDeleteFulltimeModel}
                                    />
                                    <Button
                                        className="ms-auto d-block my-3"
                                        style={{ width: 'fit-content' }}
                                        onClick={() => {
                                            submitAllUpdatedFulltimeEquations(scenario_id);
                                        }}
                                    >
                                        Save Fulltime Equations
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                    <div style={{ width: 'fit-content' }}>
                        {!!notification && (
                            <InlineNotification
                                title=""
                                subtitle={notification}
                                kind={notificationType}
                                onClose={closeNotification}
                            />
                        )}
                    </div>
                    {showUpdateModel ? (
                        <>
                            <div className="divider" />
                            <UpdateModel
                                action={action}
                                model={model}
                                onCloseUpdateModel={handleCloseUpdateModel}
                                onAddUpdatedModel={handleAddUpdatedModel}
                                models={models}
                                submitting={submitting}
                                // new stuff
                                cmdList={cmdList}
                            />
                        </>
                    ) : null}

                    <Modal
                        open={deleteOpsId !== -1}
                        modalHeading="Are you sure you want to delete this model?"
                        primaryButtonText="Delete"
                        secondaryButtonText="Cancel"
                        onRequestClose={() => setDeleteOpsId(-1)}
                        onRequestSubmit={() => {
                            handleDelete(deleteOpsId);
                            setDeleteOpsId(-1);
                        }}
                        data-testid="ops-equations-delete-modal"
                    />
                </>
            )}
        </div>
    );
};

const useDeleteModal = ({ models, setModels }, onDeleteModel = () => {}) => {
    const [deleteOpsId, setDeleteOpsId] = useState(-1);
    const [deleteLevel, setDeleteLevel] = useState(-1);

    const handleDelete = async (modelId) => {
        const model = models.find((item) => item.modelId === modelId);
        if (model?.PARENT?.ACR_ID) {
            await deleteAircrewModel(model.PARENT.ACR_ID);
        }

        // possibly update to store model ac_id in atom
        // handles cases where deleted row is a parent
        if (deleteLevel === 0) {
            setModels(models.filter((model) => model.modelId !== modelId));
        }

        // handles where deleted row is child
        else if (deleteLevel === 1) {
            // given modelId get the parentModel
            // log models and parentModel and childModel
            const parentmodelId = modelId.split(':::')[0];
            const parentModelIndex = models.findIndex((model) => model.modelId === parentmodelId);
            const parentModel = { ...models[parentModelIndex] };
            parentModel.CHILDREN = parentModel.CHILDREN.filter((childModel) => childModel.childModelId !== modelId);

            const body = {
                PARENT: {
                    ACR_ID: parentModel.PARENT.ACR_ID,
                    CMD: parentModel.PARENT.CMD,
                    MDS: parentModel.PARENT.MDS,
                    MODEL_NAME: parentModel.PARENT.MODEL_NAME,
                },
                CHILDREN: parentModel.CHILDREN.map((childModel) => ({
                    AFSC: childModel.AFSC,
                    FAC: childModel.FAC,
                    NOTES: childModel.NOTES,
                    POC: childModel.POC,
                    SOURCE: childModel.SOURCE,
                    ARCRW_RATIO: childModel.CREW_RATIO,
                    ARCRW_COMP_ENL: childModel.CREW_COMP_ENL,
                    ARCRW_COMP_OFF: childModel.CREW_COMP_OFF,
                    CREW_POSITION: childModel.CREW_POSITION,
                    TABLE_DATE: childModel.TABLE_DATE ?? '',
                })),
            };
            await updateAircrewModel(body, scenario_id);

            // Refetch data
            onDeleteModel();
        }
    };

    return {
        deleteOpsId,
        setDeleteOpsId,
        setDeleteLevel,
        deleteLevel,
        handleDelete,
    };
};

const useFilters = () => {
    const [gsFilters, setGsFilters] = useAtom(gsFiltersAtom);
    const [filters] = useAtom(filtersAtom);

    const filteredSettings = (setting) => {
        const allSettings = ['MDS', 'CMD', 'ILC'];
        const otherSettings = allSettings.filter((listedSetting) => listedSetting !== setting);

        const otherSettingA = otherSettings[0]; //MDS
        const otherSettingB = otherSettings[1]; //CMD
        const otherSettingValueA = gsFilters[otherSettingA]; //Thunderbolt
        const otherSettingValueB = gsFilters[otherSettingB]; //ACC

        const otherSelectedA = otherSettingValueA !== '';
        const otherSelectedB = otherSettingValueB !== '';
        const bothSelected = otherSelectedA && otherSelectedB;
        const noneSelected = !otherSelectedA && !otherSelectedB;

        const determineCallback = () => {
            if (bothSelected) {
                return 'both';
            } else if (!bothSelected && otherSelectedA) {
                return otherSettings[0];
            } else if (!bothSelected && otherSelectedB) {
                return otherSettings[1];
            } else if (noneSelected) {
                return 'none';
            }
        };
        const callbacks = {
            both: (combination) =>
                combination[`${otherSettingA}_SELECT`] === gsFilters[otherSettingA] &&
                combination[`${otherSettingB}_SELECT`] === gsFilters[otherSettingB],
            none: () => true,
            [otherSettingA]: (combination) => combination[`${otherSettingA}_SELECT`] === gsFilters[otherSettingA],
            [otherSettingB]: (combination) => combination[`${otherSettingB}_SELECT`] === gsFilters[otherSettingB],
        };

        const filteredCombinations = filters.filter(callbacks[determineCallback()]);
        const filteredValues = [
            ...new Set(filteredCombinations.map((filteredCombination) => filteredCombination[`${setting}_SELECT`])),
        ].sort();
        return filteredValues;
    };

    return filteredSettings;
};

AircrewModelsTab.propTypes = aircrewModelsProps;

function useFulltimeEquations({ open, CMD = '', MDS = '', ILC = '' }) {
    const [fulltimeEqnId, setFulltimeEqnId] = useState(null);
    const [fetchedFulltimeEqn, setFetchedFulltimeEqn] = useState(false);
    const { insertBulkData, submitAllUpdatedFulltimeEquations } = useWlfEquationsState();

    const [adminCreateModelsPage, setAdminCreateModelsPage] = useAtom(adminCreateModelsPageAtom);

    useEffect(() => {
        setAdminCreateModelsPage(true);
        return () => {
            setAdminCreateModelsPage(false);
        };
    }, [setAdminCreateModelsPage]);

    useEffect(() => {
        async function asyncMount(aircrewType) {
            const { isOk, data } = await fetchOpsFullTimeEquation({
                cmd: CMD,
                mds: MDS,
                ilc: ILC,
                aircrew_type: aircrewType,
            });
            const dataToInsert = isOk ? data : [];
            insertBulkData(dataToInsert, aircrewType);
            setFetchedFulltimeEqn(true);
        }
        if (open && !fetchedFulltimeEqn) {
            asyncMount('OFF');
            asyncMount('ENL');
        }
    }, [CMD, ILC, MDS, insertBulkData, open, fetchedFulltimeEqn]);

    const handleSubmitWlfChanges = async (wlfEquation, editResult) => {
        if (editResult.isOk) {
            setFulltimeEqnId(null);
        }
        const { isOk, data } = await fetchOpsFullTimeEquation({
            cmd: CMD,
            mds: MDS,
            ilc: ILC,
            aircrew_type: wlfEquation.aircrewType,
        });
        if (isOk) {
            insertBulkData(data, wlfEquation.aircrewType);
        }
    };

    return {
        fulltimeEqnId,
        isOpenWlfEquationsModal: fulltimeEqnId !== null,
        setFulltimeEqnId,
        handleSubmitWlfChanges,
        submitAllUpdatedFulltimeEquations,
    };
}

function useUpdateModel(mds = '', onAddUpdatedModel = () => {}) {
    const [model, setModel] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [action, setAction] = useState(null);
    const [modelsUpdated, setModelsUpdated] = useState(null);

    const handleCloseUpdateModel = () => {
        setAction(null);
        setModel(null);
    };
    const handleAddUpdatedModel = async (newModel) => {
        const modelName = newModel.PARENT.MODEL_NAME;
        try {
            setSubmitting(true);
            if (action === 'edit') {
                const { isOk } = await updateAircrewModel(newModel, scenario_id);
                if (!isOk) {
                    throw new Error('Could not update model');
                } else {
                    setModelsUpdated({ type: 'update', modelName });
                }
            } else {
                const { isOk } = await createAircrewModel(newModel, scenario_id);
                if (!isOk) {
                    throw new Error('Could not create model');
                } else {
                    setModelsUpdated({ type: 'create', modelName });
                }
            }

            // Refetch data
            // setFetchedData(false);
            onAddUpdatedModel?.();
            setSubmitting(false);
            handleCloseUpdateModel();
        } catch (error) {
            console.error(error);
            setSubmitting(false);
        }
    };
    const handleOpenUpdateModel = (model, options = {}) => {
        const { childModel, modelId, modelAction } = options;
        if (model) {
            let MODEL_NAME = model.MODEL_NAME;
            if (modelAction === 'copy') {
                const date = new Date().toLocaleString('en-us', {
                    timeZone: 'UTC',
                });
                MODEL_NAME = MODEL_NAME + ' ' + date;
            }
            // handle edit
            setAction(modelAction);
            setModel({
                parent: { ...model, MODEL_NAME },
                children: childModel,
                modelId: modelId,
            });
        } else {
            // handle add
            setAction('add');
            setModel({ parent: { MDS: mds, CMD: '' }, children: [] });
        }
    };

    const showUpdateModel = action !== null && model !== null;
    const notification = !!modelsUpdated ? getInlineNotificationTitle(modelsUpdated.type, modelsUpdated.modelName) : '';

    return {
        showUpdateModel,
        notification,
        notificationType: modelsUpdated?.type === 'delete' ? 'error' : 'success',
        handleAddUpdatedModel,
        handleOpenUpdateModel,
        handleCloseUpdateModel,
        model,
        submitting,
        action,
        modelsUpdated,
    };
}
